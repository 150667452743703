
/////
// CDSS routing configuration
/////

export interface DicomNodeCDSSConfig {
    routes: DicomNodeRoute[];
    cstore?: CstoreConfig;

    // List of defined external AEs
    externalAes: ExternalAE[];
}

export type RoleHintsMap = { [roleId: string]: RoleHints };

export interface DicomNodeRoute {
    // DICOM AET used on our side
    aet: string;

    // Target CDSS app ID
    appId: string;

    // Positive / negative hints to allow for disambiguation
    roleHints: RoleHintsMap;

    // Require report approval via CDSS UI before report release
    requireReportApproval: boolean;

    // TODO: Destination CDSS workspace once we have tenancies

    // TODO: UPS workflow support
    // https://www.ihe.net/uploadedFiles/Documents/Radiology/IHE_RAD_Suppl_AIW-I.pdf
    // See section: 4.81.4.1 Query for UPS Workitems Message
    // TODO: target: "Output Destination Sequence (0040,4070) of the workitem"
    
    // For C-STORE, the manual target (destination AE)
    cstoreDestinationAE?: string;
}

export interface RoleHints {
    positiveRules: RoleHint[];
    negativeRules: RoleHint[];

    // Force role assignment based on positive rules
    forceRoleAssignment?: boolean;
}

export interface RoleHint {
    // regexp for matching the SeriesDescription (0008,103e) tag
    seriesDescription: string;
}

export interface CstoreConfig {
    enable: boolean;

    port?: number;
    // TODO: TLS etc.

    // How long to delay processing after receiving files. Defaults to 5 minutes.
    // ISO 8601 duration.
    processingDelay: string;
}

export interface ExternalAE {
    // This must be distinct
    aet: string;

    hostname: string;
    port: number; // TLS?
}
