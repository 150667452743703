import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { passwordQualityCheck } from '../../auth.service';

@Component({
  selector: 'app-password-signup',
  templateUrl: './password-signup.component.html',
  styleUrls: ['./password-signup.component.css']
})
export class PasswordSignupComponent {
  localForm = new FormGroup({
    email: new FormControl('', [ Validators.required, Validators.email ]),
    password1: new FormControl('', [Validators.required, passwordQualityCheck]),
    password2: new FormControl('', Validators.required),
  }, {
    validators: [ this.checkPasswordsEqual ]
  });

  @ViewChild(ClrForm)
  private clrForm?: ClrForm;

  constructor() { }

  private checkPasswordsEqual(group: FormGroup<{ password1: FormControl<string>; password2: FormControl<string> }>) {
    let password = group.get('password1').value;
    let password2 = group.get('password2').value;

    if (password === password2)
      return null;
    else
      return { notSame: true };
  }

  validateForm(): string | null {
    if (this.localForm.invalid) {
      this.clrForm?.markAsTouched();
      return null;
    }

    const values = this.localForm.value;
    return values.password1;
  }

  setupForm(email: string) {
    this.localForm.reset({ password1: '', password2: '', email });
    this.localForm.get('email').disable();
  }
}
