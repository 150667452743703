import { InjectionToken, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthenticationInterceptor } from './authentication/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationModule } from './authentication/authentication.module';
import { MainFrameModule } from './main-frame/main-frame.module';
import { FirstLoginComponent } from './first-login/first-login.component';
import { CurrencyAmountPipe } from './dashboard/currency-amount.pipe';
import { DashboardTransactionsComponent } from './dashboard/dashboard-transactions/dashboard-transactions.component';
import { CommonPipesModule } from './common-pipes/common-pipes.module';
import { MyInvoicesComponent } from './my-invoices/my-invoices.component';
import '@cds/core/icon/register.js';
import { ClarityIcons, downloadIcon, lockIcon, pencilIcon, trashIcon } from '@cds/core/icon';
import { environment } from 'src/environments/environment';
import { BASE_URL, TEXLIVE_BASE_URL } from 'cdss-common-lib';

ClarityIcons.addIcons(downloadIcon, pencilIcon, trashIcon, lockIcon);

@NgModule({ declarations: [
        AppComponent,
        DashboardComponent,
        FirstLoginComponent,
        CurrencyAmountPipe,
        DashboardTransactionsComponent,
        MyInvoicesComponent,
    ],
    bootstrap: [AppComponent], imports: [AppRoutingModule,
        ClarityModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AuthenticationModule,
        MainFrameModule,
        CommonPipesModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: BASE_URL, useValue: environment.baseUrl },
        { provide: TEXLIVE_BASE_URL, useValue: "" },
        CookieService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
