import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, passwordQualityCheck, SocialLoginProvider } from '../auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrWizard } from '@clr/angular';
import { PasswordSignupComponent } from './password-signup/password-signup.component';

@Component({
  selector: 'app-use-invitation',
  templateUrl: './use-invitation.component.html',
  styleUrls: ['./use-invitation.component.css']
})
export class UseInvitationComponent implements OnInit {
  id?: string;
  key?: string;

  accountType: "local" | "oauth" = "local";

  hasError = false;
  busy = true;
  defaultEmail?: string;

  socialLoginProviders: SocialLoginProvider[] = [];

  @ViewChild('wizard')
  private wizard?: ClrWizard;

  @ViewChild(PasswordSignupComponent)
  private passwordForm?: PasswordSignupComponent;

  constructor(private route: ActivatedRoute, private http: HttpClient, private auth: AuthenticationService,
    private router: Router, @Inject("Window") private window: Window,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      this.key = params.key;

      this.auth.getFrontendConfig().then(config => {
        this.http.get<{ email: string }>(config.authServiceUrl + `/api/v1/user/invite/${this.id}/${this.key}`).subscribe({
          next: invData => {
            this.defaultEmail = invData.email;
            this.passwordForm.setupForm(this.defaultEmail);
            this.busy = false;
          },
          error: err => {
            console.error("Invite GET returned error:", err);
            this.hasError = true;
            this.busy = false;
          },
        });

        this.auth.getSocialLoginProviders().subscribe(providers => {
          this.socialLoginProviders = providers;
        });
      });
    });
  }

  onFinish() {
    const password = this.passwordForm?.validateForm();
    if (!password)
      return;

    this.busy = true;

    this.auth.registerUserFromInvitation(this.id, this.key, password).subscribe({
      next: () => {
        this.busy = false;
        this.wizard?.forceFinish();
        this.router.navigateByUrl('/');
      },
      error: err => {
        this.busy = false;
      },
    });
  }

  redirectToSocialLogin(provider: SocialLoginProvider) {
    this.auth.getFrontendConfig().then(config => {
      let authUrl = config.authServiceUrl + '/api/v1';

      if (authUrl.startsWith('/') && window.location.hostname === "localhost")
        authUrl = "https://ai-dev.dedicaid.com/auth/api/v1";

      let url = authUrl + provider.url + `?invitationId=${this.id}&invitationKey=${this.key}`;
      this.window.location.href = url;
    });
  }

}
